import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import {
  Layout,
  QuoteSimple,
  PressSimple,
  LinkButton,
  SEO,
} from "../components";
import { jobs } from "../config";

const AboutUsPage = ({ data }) => {
  const metadata = data.site.siteMetadata;
  const metaPage = "nosotros";
  const metaTitle = "Acerca de nosotros";
  const metaDescription =
    "Obten más información sobre Bright y nuestra empresa. Descubre cómo Bright está promoviendo la adopción de energía limpia en México, quiénes somos y cómo unirse al equipo.";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": metadata.siteUrl,
        url: metadata.siteUrl,
        name: metadata.title,
        description: metaDescription,
        inLanguage: "es-MX",
      },
      {
        "@type": "ImageObject",
        "@id": `${metadata.siteUrl}${metadata.defaultOgImage}#primaryimage`,
        inLanguage: "es-MX",
        url: `${metadata.siteUrl}${metadata.defaultOgImage}`,
      },
      {
        "@type": "WebPage",
        "@id": `${metadata.siteUrl}/${metaPage}/#webpage`,
        url: `${metadata.siteUrl}/${metaPage}`,
        name: `${metaTitle} · ${metadata.title}`,
        isPartOf: { "@id": metadata.siteUrl },
        primaryImageOfPage: {
          "@id": `${metadata.siteUrl}${metadata.defaultOgImage}`,
        },
        breadcrumb: {
          "@id": `${metadata.siteUrl}/${metaPage}/#breadcrumb`,
        },
        inLanguage: "es-MX",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`${metadata.siteUrl}/${metaPage}`],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": `${metadata.siteUrl}/${metaPage}#breadcrumb`,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": metadata.siteUrl,
              url: metadata.siteUrl,
              name: "Inicio",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": `${metadata.siteUrl}/${metaPage}`,
              url: `${metadata.siteUrl}/${metaPage}`,
              name: `${metaTitle} · ${metadata.title}`,
            },
          },
        ],
      },
    ],
  };

  const paypalImage = getImage(data.paypalLogo.childImageSharp);
  const gmailLogo = getImage(data.gmailLogo.childImageSharp);
  const yCombinator = getImage(data.yCombinator.childImageSharp);
  const bidLogo = getImage(data.bidLogo.childImageSharp);
  const grupoBimboLogo = getImage(data.grupoBimboLogo.childImageSharp);
  const firstRoundCapitalLogo = getImage(
    data.firstRoundCapitalLogo.childImageSharp,
  );

  const investors = [
    {
      name: "Max Levchin",
      role: "Co-Fundador Paypal",
      logo: paypalImage,
    },
    {
      name: "Paul Buchheit",
      role: "Creador de Gmail",
      logo: gmailLogo,
    },
    {
      name: "YCombinator",
      role: "Incubadora",
      logo: yCombinator,
    },
    {
      name: "Banco Interamericano de Desarrollo",
      role: "",
      logo: bidLogo,
    },
    {
      name: "Daniel Servitje",
      role: "CEO Grupo Bimbo",
      logo: grupoBimboLogo,
    },
    {
      name: "First Round Capital",
      role: "",
      logo: firstRoundCapitalLogo,
    },
  ];

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaMarkup={schemaMarkup}
      />
      {/* Hero Start */}
      <div className="bg-white">
        <div className="mx-auto px-8 py-16 lg:py-24 md:max-w-3xl lg:max-w-5xl">
          <div className="text-center">
            <h1 className="tracking-tight font-bold text-gray-900 text-4xl md:text-5xl">
              <span className="block">Promovemos la conciencia de la </span>{" "}
              <span className="block text-yellow-500 mt-2">
                energía limpia en México
              </span>
            </h1>
            {/* <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Fusce euismod lorem sit amet odio luctus fringilla. Sed eget urna
              a nisl rutrum mattis ut quis mauris. Vestibulum vulputate metus
              ligula, quis mollis nulla.
            </p> */}
          </div>
        </div>
      </div>
      {/* Hero End */}
      {/* Us Start */}
      <div className="bg-gray-50">
        <div className="flex flex-col lg:flex-row mx-auto px-8 py-16 lg:py-24 justify-center lg:justify-between max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="flex w-full lg:w-1/2 items-center justify-center lg:justify-start px-8 mt-6 lg:mt-0">
            <StaticImage
              alt="Casa con paneles solares con persona alcanzado el sol"
              height={450}
              src="../images/illustrations/concept_house_with_solar_panels.png"
            />
          </div>

          <div className="flex w-full lg:w-1/2 justify-center items-center px-8 text-center lg:text-left">
            <div>
              <h2 className="mt-8 md:mt-5 text-4xl lg:text-4xl text-gray-700 font-bold">
                Bright es tu aliado en energía solar
              </h2>
              <p className="mt-5 md:mt-5 text-base md:text-xl lg:text-lg xl:text-xl text-gray-500">
                Somos la empresa de energía solar que busca combinar la más alta
                tecnología y capital de Silicon Valley con el mejor talento de
                México.
              </p>
              <p className="mt-5 md:mt-5 text-base md:text-xl lg:text-lg xl:text-xl text-gray-500">
                Con un equipo joven e innovador, en Bright buscamos proporcionar
                una fuente de energía limpia de manera accesible y confiable.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Us End */}

      {/* Why México Start */}
      <div className="bg-white">
        <div className="flex flex-col lg:flex-row mx-auto px-8 py-16 lg:py-24 justify-center lg:justify-between max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="flex w-full lg:w-1/2 justify-center items-center px-8 text-center lg:text-left">
            <div>
              <h2 className="mt-8 md:mt-5 text-4xl lg:text-4xl text-gray-700 font-bold">
                ¿Por qué México?
              </h2>
              <p className="mt-5 md:mt-5 text-base md:text-xl lg:text-lg xl:text-xl text-gray-500">
                Nuestro país goza de una situación privilegiada en cuanto a
                irradiación solar, con un promedio anual de 5.3 kwh/m² por día.
              </p>
              <p className="mt-5 md:mt-5 text-base md:text-xl lg:text-lg xl:text-xl text-gray-500">
                México es el 3er país más soleado del mundo y de los países con
                tarifas más altas que van creciendo año con año.
              </p>
            </div>
          </div>
          <div className="flex w-full lg:w-1/2 items-center justify-center lg:justify-start px-8 mt-6 lg:mt-0">
            <StaticImage
              alt="Gráfica de radiación solar en México"
              height={700}
              src="../images/illustrations/mexico_solar_graph.png"
            />
          </div>
        </div>
      </div>
      {/* Why México End */}

      {/* Coverture Start */}
      <div className="bg-gray-50">
        <div className="flex flex-col lg:flex-row mx-auto px-8 py-16 lg:py-24 justify-center lg:justify-between max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="flex w-full lg:w-1/2 items-center justify-center lg:justify-start px-8 mt-6 lg:mt-0">
            <StaticImage
              alt="Gráfica de Bright en México"
              height={700}
              src="../images/illustrations/mexico_simple_coberture.png"
            />
          </div>
          <div className="flex w-full lg:w-1/2 justify-center items-center px-8 text-center lg:text-left">
            <div>
              <h2 className="mt-8 md:mt-5 text-4xl lg:text-4xl text-gray-700 font-bold">
                ¿Dónde nos encontramos?
              </h2>
              <p className="mt-5 md:mt-5 text-base md:text-xl lg:text-lg xl:text-xl text-gray-500">
                Tenemos más de +3,000 suscriptores en +60 ciudades de la
                República Mexicana que ya gozan de energía solar con Bright
              </p>
              <div className="flex mt-8 justify-center lg:justify-start">
                <LinkButton outlined arrow to="/cobertura">
                  Ver detalle de cobertura
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Coverture End */}

      {/* Investors Start */}
      <div className="bg-white">
        <div className="mx-auto px-8 py-16 lg:py-24 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
            <div className="space-y-5 sm:space-y-4 text-center lg:text-left">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                Conoce a nuestros inversionistas
              </h2>
              <p className="text-xl text-gray-500">
                Somos la Startup con más capital levantado en el país: Más de
                $30,000,000 USD
              </p>
            </div>
            <div className="lg:col-span-2">
              <ul
                role="list"
                className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8"
              >
                {investors.map((person) => (
                  <li key={person.name}>
                    <div className="flex items-center space-x-4 lg:space-x-6">
                      <GatsbyImage
                        alt={person.role}
                        className="w-14 h-14 lg:w-16 lg:h-16"
                        image={person.logo}
                      />
                      <div className="font-medium text-lg leading-6 space-y-1">
                        <h3>{person.name}</h3>
                        <p className="text-gray-400">{person.role}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Investors End */}
      {/* Quote Start */}
      <QuoteSimple
        quote="Me complace asociarme con Bright, ya que trabajan para ampliar el acceso a energía solar en México."
        author="Leonardo DiCaprio"
        title="Inversionista"
      />
      {/* Quote End */}

      {/* Press Start */}
      <PressSimple background="bg-white" />
      {/* Press End */}
      {/* Join Us Start */}
      <div className="bg-gray-50">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-blue-500 sm:text-4xl">
            Únete a nuestro equipo
          </h2>
          <p className="mt-6 text-lg leading-6 text-gray-600">
            Nos apasiona difundir la energía solar tan rápido como sea
            humanamente posible mientras nos divertimos haciéndolo.
          </p>
          <div className="max-w-xs mx-auto mt-6">
            <LinkButton shadow arrow size="large" to={jobs.href}>
              Ver ofertas de trabajo
            </LinkButton>
          </div>
        </div>
      </div>
      {/* Join Us End */}
    </Layout>
  );
};

export default AboutUsPage;

export const query = graphql`
  fragment investorImage on File {
    childImageSharp {
      gatsbyImageData(height: 400)
    }
  }

  query {
    paypalLogo: file(relativePath: { eq: "logos/paypal_logo.png" }) {
      ...investorImage
    }
    gmailLogo: file(relativePath: { eq: "logos/gmail_logo.png" }) {
      ...investorImage
    }
    yCombinator: file(relativePath: { eq: "logos/y_combinator_logo.png" }) {
      ...investorImage
    }
    bidLogo: file(
      relativePath: { eq: "logos/banco_interamericano_de_desarrollo_logo.png" }
    ) {
      ...investorImage
    }
    grupoBimboLogo: file(relativePath: { eq: "logos/grupo_bimbo_logo.png" }) {
      ...investorImage
    }
    firstRoundCapitalLogo: file(
      relativePath: { eq: "logos/first_round_capital_logo.png" }
    ) {
      ...investorImage
    }
    site {
      ...SiteInformationFragment
    }
  }
`;
